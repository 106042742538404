<template>
  <div class="radio">
    <MemberHeader/>
    <div class="radio-contents">
      <div class="list-titles">
        <div class="list-title" @click="dispContents(value.id)" v-for="(value, key) in datas" :key="key">
          <span>{{ value.title }}</span>
          <i class="ph ph-caret-right"></i>
        </div>
      </div>
      <div class="contents">
        <div id="title">{{ title }}</div>
        <div v-if="youtube != null" id="youtube">
          <YouTube :src="youtube" @ready="onReady" :width="youtubeWidth" :height="youtubeHeight" :vars="vars" ref="youtube" />
        </div>
        <div v-if="dateFmt != null" id="date">開催日：{{ dateFmt }}</div>
        <div id="overview"></div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "../../main.js"
import { collection, getDocs } from "firebase/firestore";
import YouTube from 'vue3-youtube';
import { getAuth, onAuthStateChanged } from "firebase/auth";

import MemberHeader from '../../components/MemberHeader.vue';

export default {
  name: 'Radio',
  components: {
    MemberHeader,
    YouTube
  },
  props: {
  },
  data() {
    return {
      title: null,
      youtube: null,
      radioDate: null,
      youtubeWidth: 640,
      youtubeHeight: 360,
      datas: [],
      vars: {
        autoplay: 0
      },
      radioContents: [],
    }
  },
  beforeCreate() {
    onAuthStateChanged(getAuth(), (user) => {
      if (!user) {
        this.$router.push("/member/signin");
      }
    });
  },
  mounted() {
    console.log("Radio");

		document.getElementById("account").style.color = "black";
		document.getElementsByClassName("menu").forEach(x => {
			x.style.color = "black";
		});

    this.onloadFunc();
  },
  methods: {
    async onloadFunc() {
      const querySnapshot = await getDocs(collection(firebase.db, "Radio"));
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        data.id = doc.id;
        data.dateFmt = data.date ? this.formatDate(data.date) : null;
        this.datas.push(data);
      });

      this.datas.sort((a, b) => a.no - b.no);

      let firstSel = document.createElement("option");
      firstSel.value = "";
      this.radioContents.unshift(firstSel);
    },
    dispContents(id) {
      let target = null;
      if (id) {
        target = this.datas.find(element => element.id == id);
      } else {
        target = this.datas.find(element => element.id == document.getElementById("select-radio-contents").value);
      }
      if (target) {
        this.title = target.title;
        this.youtube = target.url;
        this.dateFmt = target.dateFmt;
        if (window.matchMedia && window.matchMedia('(max-device-width: 640px)').matches) {
          this.youtubeWidth = window.innerWidth - 20;
          this.youtubeHeight = this.youtubeWidth * 0.5625;
        }
        document.getElementById("overview").innerHTML = target.overview;
      }
    },
    onReady() {
      this.$refs.youtube.stopVideo();
    },
    formatDate(datetime) {
      var date = new Date(1970, 0, 1);
      date.setSeconds(datetime.seconds);
      date.setHours(date.getHours() + 9);
      return date.getFullYear() + "年"
          + (date.getMonth() + 1) + "月"
          + date.getDate() + "日";
    },
  }
}
</script>

<style scoped>
.radio {
  width: 70%;
	margin: 5vw auto 0;
}
.radio-contents {
  display: inline-flex;
  width: 100%;
  margin-top: 1rem;
}
.contents {
  width: 90%;
  margin-top: 0.6vw;
  margin-left: 1.6vw;
}
.list-titles-sp {
  display: none;
}
.list-titles {
  text-align: left;
  margin-left: 1vw;
  width: 30%;
}
.list-titles div {
  margin-left: 1vw;
}
.list-title-category {
  font-weight: bold;
  display: list-item;
  line-height: 2vw;
}
.list-title {
  border: 1px solid aliceblue;
  background-color: aliceblue;
  width: 20vw;
  padding-left: 0.5vw;
  margin-top: 0.3vw;
}
#title {
  text-align: left;
  font-size: 1.4vw;
  font-weight: bold;
}
#date {
  margin: 0.5vw 0;
  text-align: left;
}
#overview {
  margin-top: 0.5vw;
  text-align: left;
  white-space: pre-line;
  height: 10vw;
  overflow-y: auto;
}
#youtube {
  margin-left: 0.6vw;
}
@media screen and (max-width: 640px) {
  .radio-contents {
    width: 100%;
    display: inline-grid;
  }
  .list-titles {
    display: none;
  }
  .list-titles-sp {
    display: grid;
    margin: 2vw auto;
  }
  .contents {
    width: 100%;
  }
  .select-radio {
    width: 40vw;
    height: 7vw;
    margin-top: 2vw;
  }
  #title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }
  #overview {
    text-align: left;
    white-space: pre-line;
    overflow-wrap: anywhere;
    width: 80%;
    margin: 2vw auto;
  }
  #date {
    margin-top: 0vw;
    text-align: center;
    margin-bottom: 6vw;
  }
  #youtube {
    display: inline-grid;
    margin: 0 auto;
  }
}
</style>
