<template>
	<ag-grid-vue
		:rowData="musics"
		:gridOptions="gridOptions"
		class="ag-theme-quartz">
	</ag-grid-vue>
	<form class="box">
		<div class="title-image-wrap">
			<div class="title-image">
				<div class="field">
					<label class="label">曲名（ja）</label>
					<div class="control">
						<input class="input" type="text" placeholder="" />
					</div>
				</div>
				<div class="field">
					<label class="label">曲名（en）</label>
					<div class="control">
						<input class="input" type="text" placeholder="" />
					</div>
				</div>
				<div class="field">
					<label class="label">仮タイトル</label>
					<div class="control">
						<input class="input" type="text" placeholder="仮タイトル" />
					</div>
				</div>
			</div>
			<div class="title-image">
				<div class="img-dnd"></div>
			</div>
		</div>
		<div class="title-image-wrap">
			<div class="title-image">
				<div class="field">
					<label class="label">ISRC</label>
					<div class="control">
						<input class="input" type="text" placeholder="ISRC" />
					</div>
				</div>
			</div>
			<div class="title-image">
				<div class="field">
					<label class="label">リリースID</label>
					<div class="control">
						<input class="input" type="text" placeholder="リリースID" />
					</div>
				</div>
			</div>
		</div>
		<div class="field">
			<label class="label">Storage URL</label>
			<div class="control">
				<input class="input" type="text" placeholder="Storage URL" />
			</div>
		</div>
		<div class="field">
			<label class="label">LinkCore URL</label>
			<div class="control">
				<input class="input" type="text" placeholder="LinkCore URL" />
			</div>
		</div>
		<div class="field">
			<label class="label">YouTube URL</label>
			<div class="control">
				<input class="input" type="text" placeholder="YouTube URL" />
			</div>
		</div>
		<div class="field">
			<label class="label">Tiktok URL</label>
			<div class="control">
				<input class="input" type="text" placeholder="Tiktok URL" />
			</div>
		</div>
		<div class="field">
			<label class="label">Instagram URL</label>
			<div class="control">
				<input class="input" type="text" placeholder="Instagram URL" />
			</div>
		</div>

		<button class="button is-primary">Sign in</button>
	</form>
</template>

<script>
import firebase from "../../main.js"
import { collection, getDocs } from 'firebase/firestore';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-community/styles/ag-theme-material.css";
import {AgGridVue} from "ag-grid-vue3";


export default {
  name: "App",
	components: {
		AgGridVue
	},
	data() {
		return {
			musics : [],
			gridOptions: {
        // 列の定義
        columnDefs: [
					{ headerName: "No",               field: "no",     width: "80px" },
					{
						headerName: "",
						field: "imgUrl",
						width:"80px",
						cellStyle: {
							textAlign: 'left'
						},
						cellRenderer: (param) => {
							return `<img style="height: 20px; width: 20px" src=${param.value} />`;
						}
					},
					{ headerName: "曲名（ja）",       field: "titleJa", cellStyle: {textAlign: 'left'} },
					{ headerName: "仮タイトル",       field: "tentativeTitle", cellStyle: {textAlign: 'left'} },
					{ headerName: "ライセンス保持者", field: "lisence", width: "160px", cellStyle: {textAlign: 'left'} },
					{ headerName: "状態",             field: "status", width: "120px" },
					{ headerName: "リリース期間",     field: "releaseDateFmt", cellStyle: {textAlign: 'left'} },
					{
						headerName: "曲名（en）",
						field: "titleEn",
						width: "120px",
						cellRenderer: (param) => {
							if (param.value) {
								return `<i class="ph ph-check"></i>`;
							} else {
								return "";
							}
						}
					},
					{
						headerName: "ISRC",
						field: "isrc",
						width: "120px",
						cellRenderer: (param) => {
							if (param.value) {
								return `<i class="ph ph-check"></i>`;
							} else {
								return "";
							}
						}
					},
					{
						headerName: "リリースID",
						field: "releaseId",
						width: "120px",
						cellRenderer: (param) => {
							if (param.value) {
								return `<i class="ph ph-check"></i>`;
							} else {
								return "";
							}
						}
					},
					{
						headerName: "Storage",
						field: "storageUrl",
						width: "100px",
						cellRenderer: (param) => {
							if (param.value) {
								return `<i class="ph ph-check"></i>`;
							} else {
								return "";
							}
						}
					},
					{
						headerName: "LinkCore",
						field: "linkCore",
						width: "100px",
						cellRenderer: (param) => {
							if (param.value) {
								return `<i class="ph ph-check"></i>`;
							} else {
								return "";
							}
						}
					},
					{
						headerName: "YouTube",
						field: "youtube",
						width: "100px",
						cellRenderer: (param) => {
							if (param.value) {
								return `<i class="ph ph-check"></i>`;
							} else {
								return "";
							}
						}
					},
					{
						headerName: "Tiktok",
						field: "tiktok",
						width: "100px",
						cellRenderer: (param) => {
							if (param.value) {
								return `<i class="ph ph-check"></i>`;
							} else {
								return "";
							}
						}
					},
					{
						headerName: "Instagram",
						field: "instagram",
						width: "100px",
						cellRenderer: (param) => {
							if (param.value) {
								return `<i class="ph ph-check"></i>`;
							} else {
								return "";
							}
						}
					},
					{
						headerName: "更新日時",
						field: "updateDateFmt",
						width: "160px",
						cellStyle: {textAlign: 'left'}
					},
					{
						headerName: "更新者",
						field: "updater",
						width: "100px",
						cellStyle: {textAlign: 'left'}
					},
				],

        // １行選択モード
        rowSelection: 'single', 

        // １行の高さ(タグ属性でも指定できるが、今回はオプションにした)
        rowHeight : 37, 

        // ページング機能を有効にするかの設定。タグ属性では指定できない模様
        pagination: true, 
        paginationAutoPageSize : true,

        // EVENTS - add event callback handlers
        // 行が選択されたイベント
        onRowSelected(params) { 
          params.api.redrawRows(); // 選択されたとき行の色を変える
        },
        // 行のスタイルを返すコールバック
        getRowStyle(params) {
          // 選択行のスタイル
          if (params.node.selected) {
            return { background: '#f1e8e8' };
          } else { // 通常行のスタイル
            return { background: 'white' };
          }
        },
        // グリッドの準備完了イベント
        onGridReady(params) {
          this.gridApi = params.api;
          this.columnApi = params.columnApi;

          // カラム幅を全体幅に合わせる.
          // これを呼ばないとcolumnDefが反映されません。
          this.gridApi.sizeColumnsToFit(); 

          console.log('gridReady is called.'); 
        },
      },
		}
	},
	mounted() {
    document.getElementsByClassName("header")[0].style.marginBottom = "unset";
    this.divReload();
    window.onload = ()=> {
      this.divReload();
    }
		this.onloadFunc();
	},
	methods: {
    divReload() {
			let ringoHeight = window.innerHeight - document.getElementsByClassName("footer")[0].clientHeight;
			document.getElementsByClassName("ag-theme-quartz")[0].style.height = ringoHeight + "px";
    },
		async onloadFunc() {
      const querySnapshot = await getDocs(collection(firebase.db, "Musics"));
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        console.log(data);
        data.id = doc.id;
				data.no = parseInt(data.no);
        if (data.updateDate) {
          data.updateDateFmt = this.formatDateTime(this.toDate(data.updateDate));
        }
        if (data.releaseDate) {
          let rDate = new Date(data.releaseDate);
          const from = this.formatDate(rDate);
          const to = this.formatDate(new Date(new Date(rDate.setFullYear(rDate.getFullYear() + 1)).setDate(rDate.getDate() - 1)));
          data.releaseDateFmt = from + " - " + to;
        }
        this.musics.push(data);
      });
			this.musics.sort((a, b) => a.no - b.no);
    },
    toDate(datetime) {
      var date = new Date(1970, 0, 1);
      date.setSeconds(datetime.seconds);
      date.setHours(date.getHours() + 9);
      return date;
    },
    formatDate(date) {
      return date.getFullYear() + "/"
          + (String(date.getMonth() + 1)).padStart(2, '0') + "/"
          + (String(date.getDate())).padStart(2, '0');
    },
    formatDateTime(date) {
      return date.getFullYear() + "/"
          + (String(date.getMonth() + 1)).padStart(2, '0') + "/"
          + (String(date.getDate())).padStart(2, '0') + " "
          + String(date.getHours()).padStart(2, '0') + ":"
          + String(date.getMinutes()).padStart(2, '0');
    },
	}
};
</script>

<style scoped>
@import 'vue3-easy-data-table/dist/style.css';
.table-container {
	margin: 10px auto;
	width: 95%;
}
.easy-data-table {
  overflow-x: auto;
}
.easy-data-table thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #553c3c; /* ヘッダーの色を背景色で区別 */
}
.easy-data-table td {
  text-align: center; /* 文字を中央揃え */
}
.ag-theme-quartz {
	/* then add back a border between rows */
	--ag-row-border-style: solid;
	--ag-row-border-width: 1px;
	--ag-row-border-color: rgb(220, 220, 220);
	/* and between columns */
	--ag-cell-horizontal-border: solid rgb(220, 220, 220);
}

/* 登録ダイアログ */
.box {
	position: absolute;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  width:50%;
}
.title-image-wrap {
	display: flex;
}
.title-image {
	width: 50%;
}

.label {
	text-align: left;
}
</style>