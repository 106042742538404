<template>
  <MemberHeader/>
  <div id="research" class="research">
    <div id="update-info-fusen" class="update-info-fusen" @click="dispChaneLog"><span>変更履歴</span></div>
    <div id="update-infos" class="update-infos">
      <div class="update-info-wrap">
        <div class="update-info" @mouseenter="dispUpdateInfoEdit" @mouseleave="nonDispUpdateInfoEdit" v-for="(value) in updateInfos" :key="value">
          <div v-show="user.email == 'retronorm@gmail.com'" class="edit-comment" style="display: none;">
            <i class="ph ph-pencil" @click="openUpdateInfoEdit(value.id)"></i>
            <i class="ph ph-trash" @click="deleteUpdateInfo(value.id)"></i>
          </div>
          <div style="display: flex;">
            <div><b>更新日：</b></div>
            <div>{{ value.commentdateFmt }}</div>
          </div>
          <div style="display: flex;">
            <div style="width: 3.4vw;"><b>内容&emsp;：</b></div>
            <div v-show="value.isEdit == false" style="width: 13.5vw;" class="disp-comment">{{ value.comment }}</div>
            <div v-show="value.isEdit == true" class="edit-area">
              <div style="width: 13.5vw;" class="disp-comment">
                <textarea :id="'ui-comment_' + value.id" cols="39" rows="4" :value="value.comment"></textarea>
              </div>
              <i class="ph ph-floppy-disk" @click="updateUpdateInfo(value.id)"></i>
              <i class="ph ph-x" @click="closeUpdateInfoEdit(value.id)"></i>
            </div>
          </div>
        </div>
      </div>
      <div v-show="user && user.email && user.email == 'retronorm@gmail.com'" class="send-update-info-div">
        <textarea name="update-info" id="update-info-msg" cols="55" rows="6"></textarea>
        <i class="ph ph-paper-plane" @click="sendUpdateInfo"></i>
      </div>
    </div>
    <div id="order-fusen" class="order-fusen" @click="dispOrder"><span>要望</span></div>
    <div id="orders" class="orders">
      <div class="orders-wrap">
        <div :id="'speaker-' + value.id" class="order" @mouseenter="dispOrderEdit" @mouseleave="nonDispOrderEdit" v-for="(value) in orders" :key="value">
          <div class="edit-comment" :id="'edit_' + value.speaker" style="display: none;">
            <i class="ph ph-pencil" @click="openOrderEdit(value.id)"></i>
            <i class="ph ph-trash" @click="deleteOrder(value.id)"></i>
          </div>
          <div style="display: flex;">
            <div><b>登録日：</b></div>
            <div>{{ value.commentdateFmt }}</div>
          </div>
          <div style="display: flex;">
            <div><b>登録者：</b></div>
            <div>{{ value.hideSpeader }}</div>
          </div>
          <div style="display: flex;">
            <div style="width: 3.4vw;"><b>内容&emsp;：</b></div>
            <div v-show="value.isEdit == false" style="width: 13.5vw;" class="disp-comment">{{ value.comment }}</div>
            <div v-show="value.isEdit == true" class="edit-area">
              <div style="width: 13.5vw;" class="disp-comment">
                <textarea :id="'order-comment_' + value.id" cols="39" rows="4" :value="value.comment"></textarea>
              </div>
              <i class="ph ph-floppy-disk" @click="updateOrder(value.id)"></i>
              <i class="ph ph-x" @click="closeOrderEdit(value.id)"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="send-order-div">
        <textarea name="order" id="order-msg" cols="55" rows="6"></textarea>
        <i class="ph ph-paper-plane" @click="sendOrder"></i>
      </div>
    </div>

    <div class="search-part">
      <label class="apiKeyLabel" for="apiKey">API KEY</label>
      <input type="text" id="apiKey" placeholder="一旦入力なし">
      <div class="view-menus">
        <button id="video-view-btn" class="view-menu-active" @click="videoView" data-tooltip="Video View" >
          <i class="ph ph-chart-line" data-tooltip="" name="analytics-outline"></i>
        </button>
        <button id="channel-view-btn" class="" @click="channelView" data-tooltip="Channel View" >
          <i class="ph ph-logo-youtube" data-tooltip="" name="analytics-outline"></i>
        </button>
        <button id="gallery-view-btn" class="" @click="galleryView" data-tooltip="Gallery View" >
          <i class="ph ph-images" data-tooltip="Gallery View" name="analytics-outline"></i>
        </button>
      </div>
    </div>
    <div class="result-part-video-view">
      <table style="width: 100%;">
        <tr>
          <th style="width: 10vw;">URL or ID</th>
          <th style="width: 12vw;">タイトル</th>
          <th style="width: 12vw;">チャンネル名</th>
          <th style="width: 6vw;">サムネイル</th>
          <th style="width: 6vw;">CH登録者数</th>
          <th style="width: 6vw;" class="view-title">再生数</th>
          <th style="width: 7vw;" class="view-title">再生数<br>/ CH登録者(％)</th>
          <th style="width: 6vw;" class="comment-title">コメ数</th>
          <th style="width: 7vw;" class="comment-title">コメ数<br>/ CH総コメ数(％)</th>
          <th style="width: 6vw;" class="like-title">LIKE数</th>
          <th style="width: 7vw;" class="like-title">LIKE数<br>/ CH総LIKE数(％)</th>
          <th style="width: 7vw;" class="chViewDivView-title">CH動画数</th>
          <th style="width: 7vw;" class="chViewDivView-title">CH再生数<br>/ 再生数</th>
          <th style="width: 4vw;">時間</th>
          <th style="width: 6vw;">登録日</th>
        </tr>
        <tr @click="selectChannelExec" v-for="value of videoResearchList" :key="value">
          <td style="text-align: center;" :data-url="value.channelUrl">
            <div class="loading" :data-index="value.index"></div>
            <input type="text" :data-index="value.index" @change="search">
          </td>
          <td style="position: relative;" :data-url="value.channelUrl">
            <div :data-index="value.index"></div>
            {{ value.title }}
          </td>
          <td style="text-align: center;" :data-url="value.channelUrl">
            <a :href="value.channelUrl" target="_blank">
              {{ value.channelTitle }}
            </a>
          </td>
          <td :data-url="value.channelUrl" style="text-align: center;"><img style="height: 2vw;" :src="value.defaultThumbnail" alt=""></td>
          <td :data-url="value.channelUrl" style="text-align: right; padding-right: 10px;">{{ value.subscriberCount ? value.subscriberCount.toLocaleString() : '' }}</td>
          <td :data-url="value.channelUrl" style="text-align: right; padding-right: 10px;">{{ value.viewCount ? value.viewCount.toLocaleString() : '' }}</td>
          <td :data-url="value.channelUrl" style="text-align: right; padding-right: 10px;">{{ value.viewParcent }}</td>
          <td :data-url="value.channelUrl" style="text-align: right; padding-right: 10px;">{{ value.commentCount ? value.commentCount.toLocaleString() : ''  }}</td>
          <td :data-url="value.channelUrl" style="text-align: right; padding-right: 10px;">{{ value.commentParcent }}</td>
          <td :data-url="value.channelUrl" style="text-align: right; padding-right: 10px;">{{ value.likeCount ? value.likeCount.toLocaleString() : ''  }}</td>
          <td :data-url="value.channelUrl" style="text-align: right; padding-right: 10px;">{{ value.likeParcent }}</td>
          <td :data-url="value.channelUrl" style="text-align: right; padding-right: 10px;">{{ value.videoCount }}</td>
          <td :data-url="value.channelUrl" style="text-align: right; padding-right: 10px;">{{ value.chViewDivView }}</td>
          <td :data-url="value.channelUrl" style="text-align: right; padding-right: 10px;">{{ value.duration }}</td>
          <td :data-url="value.channelUrl" style="text-align: right; padding-right: 10px;">{{ value.publishedAt }}</td>
        </tr>
      </table>
      <div class="option-section">
        <i class="ph ph-plus" @click="addTemp"></i>
        <i class="ph ph-download" @click="csvDownload"></i>
      </div>
    </div>
    <div class="result-part-channel-view">
      <div class="result-part-channel-view-wrap">
        <table>
          <tr>
            <th>チャンネル名</th>
            <td style="text-align: center;">
              <a :href="selectChannel.channelUrl" target="_blank">
                {{ selectChannel.channelTitle }}
              </a>
            </td>
          </tr>
          <tr>
            <th>サムネイル</th>
            <td style="text-align: center;">
              <img style="height: 2vw;" :src="selectChannel.defaultThumbnail" alt="">
            </td>
          </tr>
          <tr>
            <th>CH登録者数</th>
            <td style="text-align: right; padding-right: 10px;">
              <span>
                {{ selectChannel.subscriberCount ? selectChannel.subscriberCount.toLocaleString() : '' }}
              </span>
            </td>
          </tr>
          <tr>
            <th>総視聴回数</th>
            <td style="text-align: right; padding-right: 10px;">
              <span>
                {{ selectChannel.totalViewCount }}
              </span>
            </td>
          </tr>
          <tr>
            <th>総コメント数</th>
            <td style="text-align: right; padding-right: 10px;">
              <span>
                {{ selectChannel.totalCommentCount }}
              </span>
            </td>
          </tr>
          <tr>
            <th>総LIKE数</th>
            <td style="text-align: right; padding-right: 10px;">
              <span>
                {{ selectChannel.totalLikeCount }}
              </span>
            </td>
          </tr>
          <tr>
            <th>直近３ヶ月にUploadされた動画の視聴回数</th>
            <td style="text-align: right; padding-right: 10px;">
              <span>
                {{ selectChannel.threeMonthViewCount }}
              </span>
            </td>
          </tr>
        </table>
      </div>
      <MiningChart :chart-origin-data="miningResult" :titles="titles"/>
    </div>
    <div class="result-part-gallery-view" style="display: none;">
      <div class="columns is-desktop is-multiline">
        <div class="column is-one-quarter" v-for="value of videoResearchList" :key="value">
          <img :src="value.highThumbnail" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue';
import Big from 'big.js';

import MemberHeader from '../../components/MemberHeader.vue';
import MiningChart from '../../components/MiningChart.vue';
import { getAuth, onAuthStateChanged } from "@firebase/auth";

import firebase from "../../main.js";
import { collection, getDocs, addDoc, where, query, setDoc, doc, deleteDoc } from "firebase/firestore";
import { getAnalytics, logEvent } from 'firebase/analytics';

const axios = require('axios');

export default {
  name: 'Research',
  components: {
    MemberHeader,
    MiningChart,
  },
  props: {
  },
  data() {
    return {
      apikey: 'AIzaSyDbnHs_n8Wc0acBgVRUFsI3AC8wGmNTABE',
      range: 1,
      fromToShow: false,
      resultList: [],
      videoResearchList: [],
      videoTemplate: {
        videoId: null,
        title: null,
        channelTitle: null,
        channelUrl: null,
        defaultThumbnail: null,
        highThumbnail: null,
        viewCount: null, 
        commentCount: null,
        likeCount: null,
        time: null,
        index: null,
      },
      channelResearchList: [],
      selectChannel: {
        channelUrl: null,
        channelTitle: null,
        defaultThumbnail: null,
        subscriberCount: null,
        totalViewCount: null,
        totalCommentCount: null,
        totalLikeCount: null,
        threeMonthViewCount: null,
      },
      channelTemplate: {
        channelTitle: null,
        channelUrl: null,
        totalLikeCount: null,
      },
      user: null,
      updateInfos: [],
      orders: [],
      userColors: [],
      miningResult: [],
      titles: "",
    }
  },
  beforeCreate() {
    onAuthStateChanged(getAuth(), (user) => {
      if (!user) {
        this.$router.push("/member/signin");
      }
      this.user = user;
      const analytics = getAnalytics();
      logEvent(analytics, 'research', { email: user.email });
    });
  },
  mounted() {
    console.log("Research");

    for (let i = 0; i < 5; i++) {
      let temp = JSON.parse(JSON.stringify(this.videoTemplate));
      temp.index = i;
      this.videoResearchList.push(reactive(temp));
    }

    let height = window.innerHeight - document.getElementsByClassName("header")[0].clientHeight - document.getElementsByClassName("member-header")[0].clientHeight - document.getElementsByClassName("footer")[0].clientHeight;
    document.getElementById("research").style.maxHeight = height + "px";
    document.getElementById("research").style.height = height + "px";

    this.onloadUpdateInformationsFunc();
    this.onloadOrdersFunc();

  },
  methods: {
    async onloadUpdateInformationsFunc() {
      this.updateInfos = [];
      const updateInfoDatas = await getDocs(collection(firebase.db, "UpdateInformations"));
      updateInfoDatas.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        data.commentdateFmt = this.formatDate(data.commentdate);
        data.isEdit = false;
        this.updateInfos.push(data);
      });
      this.updateInfos.sort((a, b) => b.commentdate.seconds - a.commentdate.seconds);
    },
    async onloadOrdersFunc() {
      this.orders = [];
      const orderDatas = await getDocs(collection(firebase.db, "Orders"));
      orderDatas.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        data.commentdateFmt = this.formatDate(data.commentdate);
        let hideSpeakers = data.speaker.split("@");
        const visibleSpeakers = hideSpeakers[0].slice(0, 3); 
        data.hideSpeader = visibleSpeakers.padEnd(hideSpeakers[0].length, "*") + "@" + hideSpeakers[1];
        this.getColor(data);
        data.isEdit = false;
        this.orders.push(data);
      });
      this.orders.sort((a, b) => b.commentdate.seconds - a.commentdate.seconds);
    },
    async getColor(data) {
      const q = query(collection(firebase.db, "Members"), where('email', '==', data.speaker));
      const querySnapshot = await getDocs(q);
      const color = 'color' in querySnapshot.docs[0].data() ? querySnapshot.docs[0].data().color : null;
      if (color) {
        document.getElementById("speaker-" + data.id).style.backgroundColor = color;
      }
    },
    formatDate(datetime) {
      var date = new Date(1970, 0, 1);
      date.setSeconds(datetime.seconds);
      date.setHours(date.getHours() + 9);
      return date.getFullYear() + "年"
          + (date.getMonth() + 1) + "月"
          + date.getDate() + "日 ";
    },
    dispChaneLog() {
      const updateInfoFusenDiv = document.getElementById("update-info-fusen");
      const updateInfoDiv = document.getElementById("update-infos");
      for (let i = 0; i < updateInfoDiv.classList.length; i++) {
        if (updateInfoDiv.classList[i] == "disp-part") {
          updateInfoFusenDiv.classList.remove("disp-part");
          updateInfoDiv.classList.remove("disp-part");
          return;
        }
      }
      updateInfoFusenDiv.classList.add("disp-part");
      updateInfoDiv.classList.add("disp-part");
    },
    dispOrder() {
      const orderFusenDiv = document.getElementById("order-fusen");
      const ordersDiv = document.getElementById("orders");
      for (let i = 0; i < ordersDiv.classList.length; i++) {
        if (ordersDiv.classList[i] == "disp-part") {
          orderFusenDiv.classList.remove("disp-part");
          ordersDiv.classList.remove("disp-part");
          return;
        }
      }
      orderFusenDiv.classList.add("disp-part");
      ordersDiv.classList.add("disp-part");
    },
    dispUpdateInfoEdit(e) {
      if (this.user.email != 'retronorm@gmail.com') {
        return;
      }
      e.target.children[0].style.display = "flex";
    },
    nonDispUpdateInfoEdit(e) {
      if (this.user.email != 'retronorm@gmail.com') {
        return;
      }
      e.target.children[0].style.display = "none";
    },
    dispOrderEdit(e) {
      let child = e.target.children[0];
      if (child.id == "edit_" + this.user.email) {
        child.style.display = "flex";
      }
    },
    nonDispOrderEdit(e) {
      e.target.children[0].style.display = "none";
    },
    openUpdateInfoEdit(id) {
      let target = this.updateInfos.find(x => x.id == id);
      target.isEdit = true;
    },
    closeUpdateInfoEdit(id) {
      let target = this.updateInfos.find(x => x.id == id);
      target.isEdit = false;
    },
    openOrderEdit(id) {
      let target = this.orders.find(x => x.id == id);
      target.isEdit = true;
    },
    closeOrderEdit(id) {
      let target = this.orders.find(x => x.id == id);
      target.isEdit = false;
    },
    async sendUpdateInfo() {
      const data = {
        speaker: this.user.email,
        comment: document.getElementById("update-info-msg").value,
        commentdate: new Date(),
      }
      const db = collection(firebase.db, "UpdateInformations");
      await addDoc(db, data);
      document.getElementById("update-info-msg").value = "";
      this.onloadUpdateInformationsFunc();
    },
    async updateUpdateInfo(id) {
      let updateInfo = this.updateInfos.find(x => x.id == id);
      updateInfo.comment = document.getElementById("ui-comment_" + id).value;
      updateInfo.commentdate = new Date();
      await setDoc(doc(firebase.db, "UpdateInformations", id), updateInfo);
      this.onloadUpdateInformationsFunc();
    },
    async deleteUpdateInfo(id) {
      if (confirm("削除してもいいですか？")) {
        await deleteDoc(doc(firebase.db, "UpdateInformations", id));
      } 
      this.onloadUpdateInformationsFunc();
    },
    async sendOrder() {
      const data = {
        speaker: this.user.email,
        comment: document.getElementById("order-msg").value,
        commentdate: new Date(),
      }
      const db = collection(firebase.db, "Orders");
      await addDoc(db, data);
      document.getElementById("order-msg").value = "";
      this.onloadOrdersFunc();
    },
    async updateOrder(id) {
      let order = this.orders.find(x => x.id == id);
      order.comment = document.getElementById("order-comment_" + id).value;
      order.commentdate = new Date();
      await setDoc(doc(firebase.db, "Orders", id), order);
      this.onloadOrdersFunc();
    },
    async deleteOrder(id) {
      if (confirm("削除してもいいですか？")) {
        await deleteDoc(doc(firebase.db, "Orders", id));
      } 
      this.onloadOrdersFunc();
    },
    addTemp() {
      let temp = JSON.parse(JSON.stringify(this.videoTemplate));
      temp.index = this.videoResearchList.length;
      this.videoResearchList.push(reactive(temp));
    },
    videoView() {
      document.getElementsByClassName("result-part-video-view")[0].style.display = "grid";
      document.getElementsByClassName("add-temp")[0].style.display = "inline-block";
      document.getElementsByClassName("result-part-channel-view")[0].style.display = "none";
      document.getElementsByClassName("result-part-gallery-view")[0].style.display = "none";

      document.getElementById("video-view-btn").classList.add("view-menu-active");
      document.getElementById("channel-view-btn").classList.remove("view-menu-active");
      document.getElementById("gallery-view-btn").classList.remove("view-menu-active");
    },
    channelView() {
      document.getElementsByClassName("result-part-video-view")[0].style.display = "none";
      document.getElementsByClassName("add-temp")[0].style.display = "none";
      document.getElementsByClassName("result-part-channel-view")[0].style.display = "grid";
      document.getElementsByClassName("result-part-gallery-view")[0].style.display = "none";

      document.getElementById("video-view-btn").classList.remove("view-menu-active");
      document.getElementById("channel-view-btn").classList.add("view-menu-active");
      document.getElementById("gallery-view-btn").classList.remove("view-menu-active");
    },
    galleryView() {
      document.getElementsByClassName("result-part-video-view")[0].style.display = "none";
      document.getElementsByClassName("add-temp")[0].style.display = "none";
      document.getElementsByClassName("result-part-channel-view")[0].style.display = "none";
      document.getElementsByClassName("result-part-gallery-view")[0].style.display = "grid";

      document.getElementById("video-view-btn").classList.remove("view-menu-active");
      document.getElementById("channel-view-btn").classList.remove("view-menu-active");
      document.getElementById("gallery-view-btn").classList.add("view-menu-active");
    },
    async search(e) {
      let threeMonthAgo = new Date();
      threeMonthAgo.setMonth(threeMonthAgo.getMonth() - 3);
      let targetIndex = e.target.dataset.index;
      let targetVideoId = e.target.value;
      if (!targetVideoId) {
        return;
      }

      let targetLoader = null;
      Array.from(document.getElementsByClassName("loading")).forEach(x => {
        if (x.dataset.index == targetIndex) {
          targetLoader = x;
        }
      });
      targetLoader.classList.add("loader");

      targetVideoId = targetVideoId.replace("https://www.youtube.com/watch?v=", "");
      targetVideoId = targetVideoId.replace("https://youtu.be/", "");

      let targetVideoIdUrl = 'https://www.googleapis.com/youtube/v3/videos';
          targetVideoIdUrl += '?part=statistics,snippet,contentDetails'; // レスポンスに含める情報を指定
          targetVideoIdUrl += '&id=' + targetVideoId;
          targetVideoIdUrl += '&maxResult=50';
          targetVideoIdUrl += '&key=' + this.apikey;
      
      let targetVideoResult = await this.execYoutubeApi(targetVideoIdUrl);
      if (targetVideoResult.data.pageInfo.totalResults == 0) {
        e.target.placeholder = "有効ではないIDが入力されています";
        e.target.value = "";
        targetLoader.classList.remove("loader");
        return;
      }

      let targetVideo = targetVideoResult.data.items[0];
      let channelUrl = 'https://www.googleapis.com/youtube/v3/channels';
          channelUrl += '?part=statistics,snippet,contentDetails'; // レスポンスに含める情報を指定
          channelUrl += '&id=' + targetVideo.snippet.channelId;
          channelUrl += '&key=' + this.apikey;
      console.log(channelUrl);

      let channelResult = await this.execYoutubeApi(channelUrl);
      let channelInfo = channelResult.data.items[0];

      let playlistUrl = 'https://www.googleapis.com/youtube/v3/playlistItems';
          playlistUrl += '?part=snippet'
          playlistUrl += '&playlistId=' + channelInfo.contentDetails.relatedPlaylists.uploads;
          playlistUrl += '&maxResult=50';
          playlistUrl += '&key=' + this.apikey;
      console.log(playlistUrl);

      let videoIds = [];
      let playlistResult = await this.execYoutubeApi(playlistUrl);
      while (playlistResult.data.nextPageToken) {
        for (let i = 0; i < playlistResult.data.items.length; i++) {
          videoIds.push(playlistResult.data.items[i].snippet.resourceId.videoId);
        }
        playlistResult = await this.execYoutubeApi(playlistUrl, playlistResult.data.nextPageToken);
      }

      const slicer = (array, number) => {
        const length = Math.ceil(array.length / number)
        return new Array(length).fill().map((_, i) =>
          array.slice(i * number, (i + 1) * number)
        )
      }
      let sliceVideoIds = slicer(videoIds, 50);
      let videos = [];
      for (let sVideoIds of sliceVideoIds) {
        let playlistVideoIdsUrl = 'https://www.googleapis.com/youtube/v3/videos';
            playlistVideoIdsUrl += '?part=statistics,snippet,contentDetails'; // レスポンスに含める情報を指定
            playlistVideoIdsUrl += '&id=' + sVideoIds.join(",");
            playlistVideoIdsUrl += '&maxResult=50';
            playlistVideoIdsUrl += '&key=' + this.apikey;
        console.log(playlistVideoIdsUrl);
        
        let playlistVideoResult = await this.execYoutubeApi(playlistVideoIdsUrl);
        for (let i = 0; i < playlistVideoResult.data.items.length; i++) {
          videos.push(playlistVideoResult.data.items[i]);
        }
      }

      let totalCommentCount = 0;
      let totalLikeCount = 0;
      let totalViewCount = 0;
      let duration = 0;
      let threeMonthViewCount = 0;
      for (let i = 0; i < videos.length; i++) {
        let videoItem = videos[i];
        totalCommentCount += videoItem.statistics.commentCount ? Number(videoItem.statistics.commentCount) : 0;
        totalLikeCount += videoItem.statistics.likeCount ? Number(videoItem.statistics.likeCount) : 0;
        totalViewCount += videoItem.statistics.viewCount ? Number(videoItem.statistics.viewCount) : 0;

        let videoPublishedAt = new Date(videoItem.snippet.publishedAt).getTime() - threeMonthAgo.getTime();
        if (videoPublishedAt >= 0) {
          threeMonthViewCount += Number(videoItem.statistics.viewCount);
        }
      }

      let commentCount = targetVideo.statistics.commentCount > 0 ? new Big(targetVideo.statistics.commentCount): 0;
      let likeCount = targetVideo.statistics.likeCount > 0 ? new Big(targetVideo.statistics.likeCount): 0;
      let viewCount = targetVideo.statistics.viewCount > 0 ? new Big(targetVideo.statistics.viewCount): 0;
      let subscriberCount = channelInfo.statistics.subscriberCount > 0 ? new Big(channelInfo.statistics.subscriberCount): 0;

      targetVideo.commentParcent = commentCount > 0 && totalCommentCount > 0 ? commentCount.div(totalCommentCount).times(100).round(3) : 0;
      targetVideo.likeParcent = likeCount > 0 && totalLikeCount > 0 ? likeCount.div(totalLikeCount).times(100).round(3) : 0;
      targetVideo.viewParcent = viewCount > 0 && subscriberCount > 0 ? viewCount.div(subscriberCount).times(100).round(3) : 0;
      targetVideo.chViewDivView = viewCount > 0 && totalViewCount > 0 ? Number(totalViewCount / viewCount) : 0;

      let dura = 0;
      if (targetVideo.contentDetails.duration.indexOf("H") >= 0) {
        let d = targetVideo.contentDetails.duration.replace(/PT(\d+)H(\d+)M(\d+)S/, "$1:$2:$3").split(":");
        dura += (Number(d[0]) * 3600) + (Number(d[1]) * 60) + Number(d[2]);

      } else if (targetVideo.contentDetails.duration.indexOf("M") >= 0) {
        let d = targetVideo.contentDetails.duration.replace(/PT(\d+)M(\d+)S/, "$1:$2").split(":");
        dura += (Number(d[0]) * 60) + Number(d[1]);

      } else {
        let d = targetVideo.contentDetails.duration.replace(/PT(\d+)S/, "$1").split(":");
        dura += Number(d[0]);

      }
      let rate = 26;
      if (dura < 30) {
        rate = 82;
      } else if (dura < 60) {
        rate = 76;
      } else if (dura < 120) {
        rate = 68;
        dura = dura / 60;
      } else if (dura < 180) {
        rate = 63;
        dura = dura / 60;
      } else if (dura < 240) {
        rate = 61;
        dura = dura / 60;
      } else if (dura < 300) {
        rate = 60;
        dura = dura / 60;
      } else if (dura < 600) {
        rate = 56;
        dura = dura / 60;
      } else if (dura < 1200) {
        rate = 47;
        dura = dura / 60;
      } else if (dura < 1800) {
        rate = 37;
        dura = dura / 60;
      } else if (dura < 2700) {
        rate = 31;
        dura = dura / 60;
      } else if (dura < 3600) {
        rate = 28;
        dura = dura / 60;
      }
      duration = (Math.round(dura) * (rate / 100)) * viewCount;
      targetVideo.duration = (duration / 60) + (duration % 60);

      targetVideo.url = "https://www.youtube.com/watch?v=" + targetVideo.id.videoId;
      
      let videoRow = null;
      for (const row of this.videoResearchList) {
        if (row.index == targetIndex) {
          videoRow = row;
          break;
        }
      }

      let publishedAt = new Date(targetVideo.snippet.publishedAt);

      videoRow.title = targetVideo.snippet.title;
      videoRow.channelTitle = targetVideo.snippet.channelTitle;
      videoRow.channelUrl = "https://www.youtube.com/" + channelInfo.snippet.customUrl;
      videoRow.defaultThumbnail = targetVideo.snippet.thumbnails.default.url;
      videoRow.highThumbnail = targetVideo.snippet.thumbnails.high.url;
      videoRow.subscriberCount = channelInfo.statistics.subscriberCount;
      videoRow.viewCount = targetVideo.statistics.viewCount;
      videoRow.viewParcent = targetVideo.viewParcent;
      videoRow.likeCount = targetVideo.statistics.likeCount;
      videoRow.likeParcent = targetVideo.likeParcent;
      videoRow.commentCount = targetVideo.statistics.commentCount;
      videoRow.commentParcent = targetVideo.commentParcent;
      videoRow.videoCount = channelInfo.statistics.videoCount;
      videoRow.chViewDivView = targetVideo.chViewDivView;
      videoRow.publishedAt = `${publishedAt.getFullYear()}-${(publishedAt.getMonth()+1).toString().padStart(2, '0')}-${(publishedAt.getDate()).toString().padStart(2, '0')}`.replace(/\n|\r/g, '');

      videoRow.duration = Math.round(duration); 

      // CHANNEL
      let channelRow = JSON.parse(JSON.stringify(this.channelTemplate));
      channelRow.channelTitle = targetVideo.snippet.channelTitle;
      channelRow.channelUrl = "https://www.youtube.com/" + channelInfo.snippet.customUrl;
      channelRow.defaultThumbnail = channelInfo.snippet.thumbnails.default.url;
      channelRow.subscriberCount = channelInfo.statistics.subscriberCount;
      channelRow.totalCommentCount = totalCommentCount;
      channelRow.totalLikeCount = totalLikeCount;
      channelRow.totalViewCount = totalViewCount;
      channelRow.threeMonthViewCount = threeMonthViewCount;
      this.channelResearchList.push(reactive(channelRow));      

      this.resultList = targetVideo;

      e.target.disabled = true;
      e.target.value = targetVideoId;

      targetLoader.classList.remove("loader");

      let miningUrl = process.env.VUE_APP_FUNCTIONS_URL + "kuromoji?token=" + videoRow.title;
      let mr = this.miningResult;
      await axios.get(miningUrl)
      .then(function (response) {
        for (let i = 0; i < response.data.length; i++) {
          const res = response.data[i];
          const already = mr.find(x => x.word == res.surface_form);
          if (already) {
            already.count++;
          } else {
            mr.push({word: res.surface_form, count: 1, pos: res.pos});
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
      this.titles += videoRow.title;
    },
    async execYoutubeApi(url, token) {
      let execUrl = url;
      if (token) {
        execUrl = url + '&pageToken=' + token;
      }
      const ret = await axios.get(execUrl)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
      return ret;
    },
    csvDownload() {
      let hasData = false;
      let csvData = "タイトル,チャンネル名,サムネイル,再生数,再生数 / CH登録者(％),コメ数,コメ数 / CH総コメ数(％),LIKE数,LIKE数 / CH総LIKE数(％),時間,登録日\n";
      for (const row of this.videoResearchList) {
        if (row.title) {
          csvData += row.title + ",";
          csvData += row.channelTitle + ",";
          csvData += row.defaultThumbnail + ",";
          csvData += row.viewCount + ",";
          csvData += row.viewParcent + ",";
          csvData += row.commentCount + ",";
          csvData += row.commentParcent + ",";
          csvData += row.likeCount + ",";
          csvData += row.likeParcent + ",";
          csvData += row.duration + ",";
          csvData += row.publishedAt + "\n";
          hasData = true;
        }
      }
      if (!hasData) {
        alert("データがありません");
        return;
      }
      let blob = new Blob([csvData],{type:"text/csv"});
      let link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "template.csv";
      link.click();
    },
    selectChannelExec(e) {
      if (e.target.dataset.url) {
        this.selectChannel = this.channelResearchList.find(x => x.channelUrl == e.target.dataset.url);
      }
    }
  }
}
</script>

<style scoped>
.research {
  font-family: 'Kiwi Maru', cursive;
  width: 100%;
	margin: 5vw auto 0;
  overflow-y: auto;
}
.disp-part {
  animation: disp-part-animation 0.5s forwards;
}
@keyframes disp-part-animation {
  0% {
    transform: translateX(0px);
  } 
  100% {
    transform: translateX(-20vw);
  }
}
.edit-comment {
  position: absolute;
  right: 0;
}
.edit-comment i {
  width: 1vw;
  height: 1vw;
  margin-right: 10px;
  cursor: pointer;
}
.update-info-fusen {
  position: absolute;
  background-color: rgb(36 169 29);
  width: 2vw;
  height: 6vw;
  border-radius: 10px 0 0 10px;
  right: 0;
}
.update-info-fusen span {
  writing-mode: vertical-rl;
  text-align: center;
  position: initial;
  font-size: 1.2rem;
  margin-top: 0.4vw;
  color: white;
  cursor: default;
}
.update-infos {
  background-color: rgb(228, 239, 227);
  border: 1px solid rgb(36 169 29);
  position: absolute;
  width: 20vw;
  height: 38vw;
  right: -20vw;
  z-index: 999;
}
.update-info-wrap {
  width: 92%;
  margin: 0 auto;
  height: 29vw;
  overflow-y: auto;
}
.update-info {
  position: relative;
  width: 100%;
  border: 1px solid lightgray;
  margin: 0.5vw auto;
  background-color: #f1f1f1;
  text-align: left;
}
.order-fusen {
  position: absolute;
  background-color: rgb(24, 141, 145);
  width: 2vw;
  height: 6vw;
  border-radius: 10px 0 0 10px;
  top: 15.6vw;
  right: 0;
}
.order-fusen span {
  writing-mode: vertical-rl;
  text-align: center;
  position: initial;
  font-size: 1.2rem;
  margin-top: 0.4vw;
  color: white;
  cursor: default;
}
.orders {
  background-color: #f7f7f7;
  border-color: rgb(24, 141, 145);
  border-width: 2px;
  border-style: solid;
  position: absolute;
  width: 20vw;
  height: 38vw;
  right: -20vw;
  z-index: 999;
}
.orders-wrap {
  width: 92%;
  margin: 0 auto;
  height: 29vw;
  overflow-y: auto;
}
.order {
  position: relative;
  width: 100%;
  border: 1px solid lightgray;
  margin: 0.5vw auto;
  background-color: #f1f1f1;
  text-align: left;
}
.send-update-info-div,
.send-order-div {
  position: absolute;
  bottom: 10px;
}
#update-info-msg,
#order-msg {
  resize: vertica;
  padding: 6px;
}
.edit-update-info,
.edit-order {
  position: relative;
  width: 1vw;
  height: 1vw;
  float: right;
  margin-right: 10px;
  cursor: pointer;
}
.close-edit {
  width: 1vw;
  height: 1vw;
  cursor: pointer;
}
.send-update-info,
.send-order {
  position: relative;
  width: 1.2vw;
  height: 1.2vw;
  float: right;
  margin-right: 16px;
  cursor: pointer;
}
.disp-comment {
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
}
.apiKeyLabel {
  font-size: 1vw;
  line-height: 1.6vw;
  vertical-align: middle;
  margin-right: 1vw;
}
#apiKey {
  width: 20vw;
  height: 1.6vw;
  border-radius: 5px;
  border-width: 1px;
}
input {
  margin: 0;
  width: 12vw;
  height: 1.4vw;
  border-radius: 5px;
  border-width: 1px;
}
button {
  background-color: unset;
  border: none;
  padding: 0.1vw 0.6vw;
}
.search-part {
  margin: 1vw auto;
  width: 90%;
  position: relative;
}
.result-part-video-view {
  margin: 1vw auto;
  width: 90%;
  font-size: 0.9rem;
  max-height: 28vw;
  overflow-x: auto;
}
.result-part-video-view table {
  margin: 0 auto;
  border-collapse: separate;
  border-spacing: 2px;
  padding: 1vw 0.6vw;
  background-color: #ffdede;
  border-radius: 10px;
}
.result-part-video-view table th {
  background-color: aliceblue;
  vertical-align: bottom;
}
.result-part-video-view table th,
.result-part-video-view table td {
  padding: 0.1vw;
  border-radius: 5px;
}
.result-part-video-view table td {
  vertical-align: middle;
  text-align: left;
}
.result-part-video-view table tr:nth-child(even) {
	background: #ffffff;
}
.result-part-video-view table tr:nth-child(odd) {
	background: #f7f7f7;
}
.range-btn {
  min-width: 2vw;
  margin-right: 0.3vw;
}
.active {
  background-color: bisque;
}
.option-section {
  position: relative;
  width: 90%;
  margin: 0 auto;
}
.add-temp {
  width: 2vw;
  height: 2vw;
  cursor: pointer;
}
.csv-download {
  position: absolute;
  width: 2vw;
  height: 2vw;
  right: 0;
  cursor: pointer;
}
.view-menus {
  position: absolute;
  right: 0;
  top: 0;
}
.view-menu {
  width: 1.5vw;
  height: 1.5vw;
  vertical-align: top;
}
.view-menu-active {
  background-color: lightgray;
  border-radius: 5px;
}
.view-title {
  background-color: #FFFFE0 !important;
}
.comment-title {
  background-color: #FAEBD7 !important;
}
.like-title {
  background-color: #E6E6FA !important;
}
.chViewDivView-title {
  background-color: #fddeff !important;
}
.result-part-channel-view {
  width: 90%;
  margin: 0 auto 2vw;
  display: flex;
}
.result-part-channel-view-wrap {
  position: relative;
  background-color: #e2ffee;
  width: 24vw;
  padding: 1vw;
  border-radius: 10px;
}
.result-part-channel-view table {
  font-size: 0.8rem;
  border-collapse: separate;
  border-spacing: 2px
}
.result-part-channel-view table th {
  width: 8vw;
  height: 1.6vw;
  background-color: #d8d8ea;
  padding: 0.6vw;
  border-radius: 10px;
}
.result-part-channel-view table td {
  width: 16vw;
  height: 1.6vw;
  background-color: #f1efd6;
  padding: 0.6vw;
  border-radius: 10px;
}
.result-part-gallery-view {
  width: 90%;
  margin: 0 auto;
}
.loader {
  position: absolute;
  border: 2px solid #fafafa;
  border-radius: 50%;
  border-top: 2px solid #3498db;
  width: 1vw;
  height: 1vw;
  margin-top: 0.2vw;
  left: 4.5vw;
  animation: spin 1s linear infinite;
}
@keyframes spin{
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}
</style>
