<template>
  <div class="change-regist">
    <div class="registed">
      <div class="registed-units">
        <div @click="deploymentData(value.id)" v-for="(value, key) in datas" :key="key">
          <div class="registed-unit">
            <div>日付：{{ value.dateFmt }}</div>
            <div>LOG：{{ value.log }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="regist">
      <table>
        <tr>
          <th>（開発者用）ID</th>
          <td><input class="textinput" type="text" id="dataid" name="dataid" disabled></td>
        </tr>
        <tr>
          <th>日付</th>
          <td><input class="textinput" type="text" id="date" name="date" disabled></td>
        </tr>
        <tr>
          <th>LOG</th>
          <td><textarea class="textareainput" name="log" id="log" cols="30" rows="10"></textarea></td>
        </tr>
        <tr>
          <td colspan="2">
						<i class="ph ph-floppy-disk" @click="postData"></i>
            <i class="ph ph-trash" @click="deleteData"></i>
            <i class="ph ph-arrows-clockwise" @click="clearData"></i>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import firebase from "../../main.js"
import { collection, doc, getDocs, addDoc, deleteDoc, setDoc } from "firebase/firestore";

export default {
  name: 'ChangeRegist',
  props: {
  },
  data() {
    return {
      datas: [],
    }
  },
  mounted() {
    console.log("ChangeRegist");

		document.getElementById("account").style.color = "black";
		document.getElementsByClassName("menu").forEach(x => {
			x.style.color = "black";
		});
    document.getElementById("footer").style.display = "none";

    this.onloadFunc();  
  },
  unmounted() {
    document.getElementById("footer").style.display = "grid";
  },
  methods: {
    awaitFunc() {
      this.datas = [];
      this.onloadFunc();
    },
    async onloadFunc() {
      const querySnapshot = await getDocs(collection(firebase.db, "ChangeLog"));
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        data.dateFmt = this.formatDate(data.date);
        this.datas.push(data);
      });
    },
    formatDate(datetime) {
      var date = new Date(1970, 0, 1);
      date.setSeconds(datetime.seconds);
      date.setHours(date.getHours() + 9);
      return date.getFullYear() + "年"
          + (date.getMonth() + 1) + "月"
          + date.getDate() + "日 "
          + ("00" + date.getHours()).slice(-2) + ":"
          + ("00" + date.getMinutes()).slice(-2);
    },
    async postData() {
      const data = {
        log: document.getElementById("log").value,
        date: new Date()
      }

      const dataid = document.getElementById("dataid").value;
      if (dataid) {
        await setDoc(doc(firebase.db, "ChangeLog", dataid), data);
      } else {
        const db = collection(firebase.db, "ChangeLog");
        await addDoc(db, data);
      }

      document.getElementById("dataid").value = "";
      document.getElementById("date").value = "";
      document.getElementById("log").value = "";

      this.awaitFunc();

    },
    async deleteData() {
      const id = document.getElementById("dataid").value;
      if (id) {
        if (confirm("削除してもいいですか？")) {
          await deleteDoc(doc(firebase.db, "ChangeLog", id));
        }
        this.awaitFunc();
      }

      document.getElementById("dataid").value = "";
      document.getElementById("date").value = "";
      document.getElementById("log").value = "";

    },
    clearData() {
      document.getElementById("dataid").value = "";
      document.getElementById("date").value = "";
      document.getElementById("log").value = "";
    },
    deploymentData(id) {
      const data = this.datas.find(x => x.id == id);
      if (data) {
        document.getElementById("dataid").value = data.id;
        document.getElementById("date").value = data.dateFmt;
        document.getElementById("log").value = data.log;
      }
    }
  }
}
</script>

<style scoped>
.change-regist {
  display: inline-flex;
  margin: 2vw auto;
	width: 80%;
}
.registed {
  width: 22vw;
  text-align: left;
}
.registed-units {
  overflow: auto;
  height: 30vw;
}
.registed-unit {
  margin-bottom: 0.3vw;
  background-color: aliceblue;
}
.textinput, .textareainput {
  width: 100%;
}
.textinput {
  height: 1.6vw;
}
.textareainput {
  height: 8vw;
}
.input-ud-icon {
  width: 1.8vw;
  height: 1.8vw;
  margin: 0.4vw 0.8vw;
}
.regist table {
  width: 35vw;
}
.regist th {
  text-align: right;
  vertical-align: top;
  padding-right: 0.8vw;
}
.back {
  text-align: left;
  padding-left: 1.6vw;
  text-decoration: none;
}
.back i {
  width: 1.6vw;
  height: 1.6vw;
  color: var(--color);
}
</style>
