<template>
  <div class="chat-log-regist">
    <div class="registed">
      <div class="conditions">
        <div class="sortdiv">
          <select @change="displayFunc" name="sortinstructions" id="sortinstructions">
            <option value="updatedate_desc">登録日（降順）</option>
            <option value="updatedate_asc">登録日（昇順）</option>
            <option value="commentdate_desc">コメント日付（降順）</option>
            <option value="commentdate_asc">コメント日付（昇順）</option>
          </select>
        </div>
        <div>
          <label class="dispanxietylabel" for="dispanxietyflg">不安のみ</label>
          <input @change="displayFunc" class="dispanxietyinput" type="checkbox" name="dispanxietyflg" id="dispanxietyflg">
        </div>
        <div>
          <label class="dispconfirmlabel" for="dispanxietyflg">確定非表示</label>
          <input @change="displayFunc" class="dispconfirminput" type="checkbox" name="dispconfirmflg" id="dispconfirmflg" checked>
        </div>
      </div>
      <div class="registed-units">
        <div @click="deploymentData(value.id)" v-for="(value, key) in displaydatas" :key="key">
          <div v-if="value.anxietyflg == true" :id="value.id" class="registed-unit-common registed-unit-anxiety">
            <input type="hidden" name="hidden-id" value="value.id">
            <div>登録日：{{ value.updatedateFmt }}</div>
            <div>コメント日：{{ value.commentdateFmt }}</div>
            <div>発言者：{{ value.speaker }}</div>
            <div class="dispcomment">内容：{{ value.comment }}</div>
          </div>
          <div v-else-if="value.registcomment != ''" :id="value.id" class="registed-unit-common registed-unit-comment">
            <input type="hidden" name="hidden-id" value="value.id">
            <div>登録日：{{ value.updatedateFmt }}</div>
            <div>コメント日：{{ value.commentdateFmt }}</div>
            <div>発言者：{{ value.speaker }}</div>
            <div class="dispcomment">内容：{{ value.comment }}</div>
          </div>
          <div v-else :id="value.id" class="registed-unit-common registed-unit">
            <div>登録日：{{ value.updatedateFmt }}</div>
            <div>発言日：{{ value.commentdateFmt }}</div>
            <div>発言者：{{ value.speaker }}</div>
            <div class="dispcomment">内容：{{ value.comment }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="regist">
      <table>
        <tr>
          <th>（開発者用）ID</th>
          <td class="reference"><span id="dataid"></span></td>
        </tr>
        <tr>
          <th>（参考）前回登録発言者</th>
          <td class="reference"><span id="temp-speaker"></span></td>
        </tr>
        <tr>
          <th>（参考）前回登録発言日付</th>
          <td class="reference"><span id="temp-date"></span></td>
        </tr>
        <tr>
          <th>発言日付</th>
          <td><input class="textinput" type="text" id="commentdate" name="commentdate"></td>
        </tr>
        <tr>
          <th>発言者</th>
          <td><input class="textinput" type="text" id="speaker" name="speaker"></td>
        </tr>
        <tr>
          <th>内容</th>
          <td><textarea class="comment" name="comment" id="comment" cols="30" rows="10"></textarea></td>
        </tr>
        <tr>
          <th>（登録者用）登録時コメント</th>
          <td><textarea class="register-comment" name="registcomment" id="registcomment" cols="30" rows="10"></textarea></td>
        </tr>
        <tr>
          <th>（登録者用）不安フラグ</th>
          <td class="checkboxinput-td">
            <input class="checkboxinput" type="checkbox" name="anxietyflg" id="anxietyflg">
          </td>
        </tr>
        <tr>
          <th>（登録者用）確定フラグ</th>
          <td class="checkboxinput-td">
            <input class="checkboxinput" type="checkbox" name="confirmflg" id="confirmflg">
          </td>
        </tr>
        <tr>
          <td colspan="2">
						<i class="ph ph-floppy-disk" @click="postData"></i>
						<i class="ph ph-trash" @click="deleteData"></i>
						<i class="ph ph-arrows-clockwise" @click="clearData"></i>
          </td>
        </tr>
      </table>
      
    </div>
  </div>
</template>

<script>
import firebase from "../../main.js"
import { collection, doc, getDocs, addDoc, deleteDoc, setDoc } from "firebase/firestore";

export default {
  name: 'Import',
  props: {
  },
  data() {
    return {
      datas: [],
      displaydatas: [],
    }
  },
  mounted() {
    console.log("ChatRegist");

    document.getElementById("footer").style.display = "none";

    this.onloadFunc();  
  },
  unmounted() {
    document.getElementById("footer").style.display = "grid";
  },
  methods: {
    // TODO
    // 登録データのソート（登録・更新日）、不安フラグのみの抽出
    // 登録データの展開
    awaitFunc() {
      this.datas = [];
      this.onloadFunc();
    },
    async onloadFunc() {
      const querySnapshot = await getDocs(collection(firebase.db, "ChatLog"));
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        data.commentdateFmt = this.formatDate(data.commentdate);
        data.updatedateFmt = this.formatDate(data.updatedate);
        this.datas.push(data);
      });
      this.displayFunc();
    },
    displayFunc() {
      this.displaydatas = this.datas.concat();

      const dispanxietyflg = document.getElementById("dispanxietyflg").checked;
      if (dispanxietyflg) {
        this.displaydatas = this.displaydatas.filter(x => x.anxietyflg == true);
      }

      const dispconfirmflg = document.getElementById("dispconfirmflg").checked;
      if (dispconfirmflg) {
        this.displaydatas = this.displaydatas.filter(x => x.confirmflg == false);
      }

      const instruction = document.getElementById("sortinstructions").value;
      if (instruction == "commentdate_asc") {
        this.displaydatas.sort((a, b) => a.commentdate.seconds - b.commentdate.seconds);
      } else if (instruction == "commentdate_desc") {
        this.displaydatas.sort((a, b) => b.commentdate.seconds - a.commentdate.seconds);
      } else if (instruction == "updatedate_asc") {
        this.displaydatas.sort((a, b) => a.updatedate.seconds - b.updatedate.seconds);
      } else if (instruction == "updatedate_desc") {
        this.displaydatas.sort((a, b) => b.updatedate.seconds - a.updatedate.seconds);
      }

    },
    formatDate(datetime) {
      var date = new Date(1970, 0, 1);
      date.setSeconds(datetime.seconds);
      date.setHours(date.getHours() + 9);
      return date.getFullYear() + "年"
          + (date.getMonth() + 1) + "月"
          + date.getDate() + "日 "
          + ("00" + date.getHours()).slice(-2) + ":"
          + ("00" + date.getMinutes()).slice(-2);
      
    },
    async postData() {
      let commentDate = null;
      let tempDate = null;
      let cd = document.getElementById("commentdate").value;
      if (!cd) {
        alert("すみません…日付は必須入力とさせて頂いております。");
        return;
      } else {
        let yearSplit = cd.split("年");
        let monthSplit = yearSplit[1].split("月");
        let daySplit = monthSplit[1].split("日");
        let hourSplit = daySplit[1].split(":");

        let year = yearSplit[0].trim();
        let month = parseInt(monthSplit[0].trim()) - 1;
        let day = daySplit[0].trim();
        let hour = hourSplit[0].trim();
        let time = hourSplit[1].trim();

        commentDate = new Date(year, month, day, hour, time);

        tempDate = cd.split("日")[0] + "日";
      }
      
      const speaker = document.getElementById("speaker").value;
      const data = {
        speaker: speaker,
        comment: document.getElementById("comment").value,
        commentdate: commentDate,
        registcomment: document.getElementById("registcomment").value,
        anxietyflg: document.getElementById("anxietyflg").checked,
        confirmflg: document.getElementById("confirmflg").checked,
        updatedate: new Date()
      }

      const id = document.getElementById("dataid").innerText;
      if (id) {
        await setDoc(doc(firebase.db, "ChatLog", id), data);
      } else {
        const db = collection(firebase.db, "ChatLog");
        await addDoc(db, data);
      }

      document.getElementById("dataid").innerText = "";
      document.getElementById("temp-speaker").innerText = speaker;
      document.getElementById("temp-date").innerText = tempDate;
      document.getElementById("speaker").value = "";
      document.getElementById("comment").value = "";
      document.getElementById("commentdate").value = "";
      document.getElementById("registcomment").value = ""
      document.getElementById("anxietyflg").checked = false;
      document.getElementById("confirmflg").checked = false;

      this.awaitFunc();

    },
    async deleteData() {
      const id = document.getElementById("dataid").innerText;
      if (id) {
        if (confirm("削除してもいいですか？")) {
          await deleteDoc(doc(firebase.db, "ChatLog", id));
        }
        this.awaitFunc();
        alert("削除しました");
      }

      document.getElementById("dataid").innerText = "";
      document.getElementById("temp-speaker").innerText = "";
      document.getElementById("temp-date").innerText = "";
      document.getElementById("speaker").value = "";
      document.getElementById("comment").value = "";
      document.getElementById("commentdate").value = "";
      document.getElementById("registcomment").value = ""
      document.getElementById("anxietyflg").checked = false;
      document.getElementById("confirmflg").checked = false;
    },
    clearData() {
      document.getElementById("dataid").innerText = "";
      document.getElementById("temp-speaker").innerText = "";
      document.getElementById("temp-date").innerText = "";
      document.getElementById("speaker").value = "";
      document.getElementById("comment").value = "";
      document.getElementById("commentdate").value = "";
      document.getElementById("registcomment").value = ""
      document.getElementById("anxietyflg").checked = false;
      document.getElementById("confirmflg").checked = false;
    },
    deploymentData(id) {
      const data = this.displaydatas.find(x => x.id == id);
      if (data) {
        document.getElementById("dataid").innerText = data.id;
        document.getElementById("temp-speaker").innerText = "";
        document.getElementById("temp-date").innerText = "";
        document.getElementById("speaker").value = data.speaker;
        document.getElementById("comment").value = data.comment;
        document.getElementById("commentdate").value = this.formatDate(data.commentdate);
        document.getElementById("registcomment").value = data.registcomment;
        document.getElementById("anxietyflg").checked = data.anxietyflg;
        document.getElementById("confirmflg").checked = data.confirmflg;
      }
      document.getElementsByClassName("registed-unit-common").forEach(x => x.classList.remove("registed-unit-deploy"));
      document.getElementById(id).classList.add("registed-unit-deploy");
    },
    openZoom() {
      const url = 'https://us06web.zoom.us/j/9898533594?pwd=aDRwVkxKQ3ZWWE9LOCtHcmpQSi9tdz09';
      window.open(url, '_blank');
    },
  }
}
</script>

<style scoped>
.chat-log-regist {
	display: inline-flex;
	margin: 2vw auto;
	width: 80%;
}
.conditions {
  display: inline-flex;
  height: 2vw;
}
.sortdiv {
  margin: 0;
}
select {
  font-size: 1vw;
  height: 1.8vw;
}
.hydrated {
  vertical-align: middle;
}
.dispanxietylabel,
.dispconfirmlabel {
  margin-left: 1vw;
  vertical-align: bottom;
}
.dispanxietyinput,
.dispconfirminput {
  width: 1vw;
  margin-top: 0.6vw;
  height: 1vw;
  margin-bottom: 0;
  vertical-align: inherit;
}
.registed {
  width: 50%;
  text-align: left;
  display: inline-grid;
}
.registed-units {
  overflow: auto;
  height: 27vw;
}
.registed-unit {
  margin-bottom: 0.3vw;
  background-color: aliceblue;
}
.registed-unit-anxiety {
  margin-bottom: 0.3vw;
  background-color: bisque;
}
.registed-unit-comment {
  margin-bottom: 0.3vw;
  background-color: mintcream;
}
.registed-unit-deploy {
  background-color: #ffbebe !important;
}

.textinput, .comment, .register-comment {
  width: 100%;
}
.textinput {
  height: 1.3vw;
}
.comment {
  height: 7vw;
}
.register-comment {
  height: 4vw;
}
.checkboxinput-td {
  text-align: left;
}
.checkboxinput {
  width: 1.6vw;
  margin: 0;
  height: 1.6vw;
}
.input-ud-icon {
  width: 1.8vw;
  height: 1.8vw;
  margin: 0.4vw 0.8vw;
}
.regist {
  width: 50%;
}
.regist table {
  width: 100%;
  border-spacing: 0;
}
.regist th {
  text-align: right;
  vertical-align: top;
  padding-right: 0.8vw;
  width: 35%;
}
.reference {
  text-align: left;
}
.dispcomment {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.back {
  text-align: left;
  padding-left: 1.6vw;
  text-decoration: none;
  position: absolute;
  left: 0;
}
.back i {
  width: 1.6vw;
  height: 1.6vw;
  color: var(--color);
}
.zoom {
  width: 4vw;
  cursor: pointer;
  border-radius: 5px;
}

/* a */
.heart-button {
  position: relative;
  display: inline-block;
  padding: 0.3vw 0.5vw;
  border-radius: 5px;
  color: var(--color);
  letter-spacing: 0.1em;
  transition: all 0.3s ease;
  cursor: pointer;
}

/* ハートの共通スタイル */
.heart-button::before,
.heart-button::after {
  content: "";
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  position: absolute;
  opacity: 0;
}

/* 左上のハートのスタイル */
.heart-button::before {
  background-image: url('../../assets/site_images/heart/heart.svg');
  top: 0;
  left: -12px;
}

/* 右下のハートのスタイル */
.heart-button::after {
  background-image: url('../../assets/site_images/heart/heart-reverse.svg');
  bottom: -6px;
  right: -14px;
}

/* ========= ホバー時のスタイル ======== */

/* ボタンホバー時のスタイル */
.heart-button:hover {
  background-color: #fff;
  border-color: #ef4b53;
  color: var(--color);
}

/* ボタンホバー時の左上のハートのスタイル */
.heart-button:hover::before {
  animation: heart 1.5s infinite ease-out;
}

/* ボタンホバー時の右下のハートのスタイル */
.heart-button:hover::after {
  animation: heart 1.5s 0.2s infinite ease-out;
}

/* ========= アニメーションの設定 ======== */
@keyframes heart {
  0% {
    transform: translateY(0) scale(1);
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: translateY(-20px) scale(1.4);
    opacity: 0;
  }
}
</style>
